.page-info-nav-container {
  position: absolute;
  bottom: 20px;  
  display: inline-flex;
}

.page-info-about-nav {
  font-size: 30px;
}

.page-info-about-nav a {
  text-decoration: none;
}

.page-info-body {
  width: 100%;
  text-align: center;
}

.page-info-body img {
  width: 600px;
}

.page-info-nav-line {
  height: 1px;
  width: 300px;
  background-color: #000;
  margin-left: 20px;
  transform: translateY(22px);
}

.page-info-section--1 {
  height: 20vh;
  position: relative;
}

.page-info-section--4 {
  text-align: left;
}

.page-info-title {
  width: 100%;
  text-align: center;
  font-size: 50px;
}

.page-info-subsection h2, 
.page-info-subsection p {
  margin: 0
}

.page-info--mockup-image img {
  width: 100%;
  border: 1px solid #000;
}