.about-description {
  text-indent: 2rem;
  padding-left: 100px;
  padding-right: 100px;
}

.about-title {
  text-align: center;
  padding-top: 100px;
}

.about-grid {
  margin: 0 auto;
}

.about-image {
  width: 100%;
}

.about-image-container {
  bottom: 10px;
  position: absolute;
  width: inherit;
}

.about-image--row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.about-image--row img {
  max-height: 200px;
  padding: 10px;
  cursor: pointer;
}

.about-image-title {
  width: 100%;
  text-align: center;
  font-size: 50px;
  font-weight: bold;
}

.about-image-wrapper {
  width: 100%;
  position: relative;
}

.about-nav {
  font-size: 30px;
}

.about-nav a {
  text-decoration: none;
}

.about-nav-container {
  display: inline-flex;
  width: 100%;
  overflow: hidden;
  transform: translateY(20vh);
}

.about-nav-line {
  height: 2px;
  width: 100%;
  transform: translateY(22px);
  margin-left: 20px;
  background-color: #000;
}

.about-section-1 {
  height: 30vh;
}

.about-image-wrapper,
.about-section-2 {
  height: 40vh;
}

.about-image--row-1 img {
  width: 100%;
}

.about-image-modal-wrapper {
  height: 100vh;
  position: relative;
  color: #fff;
}

.about-image-modal-container {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}

.about-image-modal-wrapper img {
  max-height: 80vh;
}

.about-image-modal-cose {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.about-loading-overlay {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}


@media (max-width: 600px) {
  .about-image-container {
    text-align: center;
  }
  .about-image {
    max-width: 150px;
    margin: 0 auto;
  }

  .about-image--row img {
    height: initial;
  }

  .about-description {
    padding-left: 20px;
    padding-right: 20px;
  }
}