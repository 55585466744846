.welcome-wrapper {
  position: relative;
}

.welcome-image-wrapper {
  position: relative;
}

.welcome-image {
  background-image: url(assets/image--1.png);
  background-size: cover;
  position: absolute;
  right: 0;
  width: 300px;
  height: 300px;
}

.welcome-image--offset-box {
  border-width: 6px;
  border-style: solid;
  position: absolute;
  transform: translateY(20px);
  right: -20px;
  width: 288px;
  height: 288px;
}

.purple-box--1 {
  position: absolute;
  width: 40%;
  height: 80vh;
  z-index: -2;
  top: 0px;
  left: 0
}

.white-vert--1 {
  height: inherit;
  position: absolute;
  width: 10px;
  top: 0;
  left: 40px;
  z-index: -1;
}

.welcome-box-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  height: inherit;
  text-align: end;
  padding-left: 20px;
  font-size: 50px;
}

.welcome-title {
  font-size: 50px;
}

.welcome-image,
.welcome-title,
.welcome-description {
  animation: fadeInAnimation ease 2s; 
}

.welcome-image-wrapper {
  min-height: 300px;
}

@media (max-width: 600px) {
  .welcome-image {
    width: 200px;
    height: 200px;
    right: 40px;
  }
  .welcome-image--offset-box {
    width: 188px;
    height: 188px;
    right: 20px;
  }
  .purple-box--1 {
    width: 60%;
  }
  .welcome-box-text {
    font-size: 30px;
  }
  .welcome-title {
    margin: 0;
  }
}

@media (max-width: 315px) {
  .welcome-image {
    width: 150px;
    height: 150px;
    right: 40px;
  }
  .welcome-image--offset-box {
    width: 138px;
    height: 138px;
    right: 20px;
  }
  .welcome-image-wrapper {
    min-height: 200px;
  }
  .welcome-box-text {
    display: none;
  }
}