/** WHITE **/

.white-background { 
  background-color: #ffffff;
}
.white-font, 
.white-font a,
.white-font a:visited { 
  color: #ffffff;
}


/** PURPLE **/

.purple-background {
  background-color: #b3a8b8;
}
.purple-font {
  color: #b3a8b8;
}



/** PURPLE SECONDARY **/
.dark-purple-background {
  background-color: #6d6479;
}


/** GRAY-1 **/

.gray-font--1,
.gray-font--1 a,
.gray-font--1 a:visited {
  color: #5b5b5b;
}
.gray-box--1 {
  border-color: #5b5b5b;
}
.gray-background--1 {
  background-color: #5b5b5b;
}

/** FONTS **/

.font--alegreya {
  font-family: 'Alegreya', serif;
}
.font--sacramento {
  font-family: 'Sacramento', cursive;
}
.font--roboto {
  font-family: 'Roboto', sans-serif;
}


/** PADDING **/
.padded-1y {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.padded-2y {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.padded-2x {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.padded-4y {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.padded-4x {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.padded-6y {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.padded-6x {
  padding-left: 60px !important;
  padding-right: 60px !important;
}


/** SECTIONS **/

.section--wrapper {
  min-height: 100vh;
}


/** GRID **/

.grid-container {
  flex-grow: 1;
  align-content: center;
}

/** ANIMATION STYLE **/
.style-hidden {
  opacity:0
}

.style-show {
  opacity:1;
  animation: 1s ease;
}

@keyframes fadeInAnimation { 
  0% { 
      opacity: 0; 
  } 
  100% { 
      opacity: 1; 
  } 
} 